import React, { useEffect, useState } from 'react';

import './salesforce-form.scss';

const ORG_ID = '00DA0000000IlZt';
const FIELD_NAMES = {
  REFERRAL_URL: '00N0f00000FtQSq',
  FIRST_NAME: '00NG000000Els9d',
  LAST_NAME: '00NG000000Els9e',
  EMAIL_ADDRESS: '00NG000000Els9c',
  DEALERSHIP_NAME: '00NG000000DhLpG',
  PRODUCT: '00N0f00000G8qXR',
  DESCRIPTION: 'description',
};
const FORM_ACTION = `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=${ORG_ID}`;
const HIDDEN_INPUTS = {
  orgid: ORG_ID,
  recordType: '012G0000001IonD',
  retURL: 'https://edmunds.com/industry/',
};
const PRODUCT_LIST = ['Advertising', 'Web Traffic', 'Website Conversion Tools', 'Vehicle Acquisition', 'Wholesale'];

export function SalesforceForm() {
  const [locationHref, setLocationHref] = useState('');
  const [goals, setGoals] = useState('');
  const [website, setWebsite] = useState('');

  const description = `${goals}\n${website}`;

  useEffect(() => {
    setLocationHref(window?.location?.href);
  }, []);

  const handleWebsiteChange = ({ target: { value } }) => setWebsite(value);
  const handleGoalsChange = ({ target: { value } }) => setGoals(value);

  return (
    <div className="edmunds-landing-page-form form-container bg-blue-100 mx-auto p-1 p-sm-1_25">
      <h1 className="mb-1_25 text-center text-cool-gray-30 size-30 fw-bold">Let&apos;s drive your sales</h1>
      <p className="mx-auto size-16 text-center">
        Edmunds has a solution for every goal &mdash; let&apos;s build a game plan that&apos;s geared for your
        dealership
      </p>
      <h2 className="heading-4 text-center">Sign up to get started</h2>
      <form action={FORM_ACTION} method="POST">
        {Object.entries(HIDDEN_INPUTS).map(([name, value]) => (
          <input key={name} type="hidden" name={name} value={value} />
        ))}
        <input id={FIELD_NAMES.REFERRAL_URL} type="hidden" name={FIELD_NAMES.REFERRAL_URL} value={locationHref} />
        <input id={FIELD_NAMES.DESCRIPTION} type="hidden" name={FIELD_NAMES.DESCRIPTION} value={description} />

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor={FIELD_NAMES.FIRST_NAME}>
            First Name
          </label>
          <input
            id={FIELD_NAMES.FIRST_NAME}
            className="w-100"
            name={FIELD_NAMES.FIRST_NAME}
            type="text"
            maxLength={60}
            placeholder="Enter your first name"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor={FIELD_NAMES.LAST_NAME}>
            Last Name
          </label>
          <input
            id={FIELD_NAMES.LAST_NAME}
            className="w-100"
            name={FIELD_NAMES.LAST_NAME}
            type="text"
            maxLength={60}
            placeholder="Enter your last name"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor={FIELD_NAMES.EMAIL_ADDRESS}>
            Email Address
          </label>
          <input
            id={FIELD_NAMES.EMAIL_ADDRESS}
            className="w-100"
            name={FIELD_NAMES.EMAIL_ADDRESS}
            type="email"
            maxLength={80}
            placeholder="Enter your email address"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor={FIELD_NAMES.DEALERSHIP_NAME}>
            Dealership Name
          </label>
          <input
            id={FIELD_NAMES.DEALERSHIP_NAME}
            className="w-100"
            name={FIELD_NAMES.DEALERSHIP_NAME}
            type="text"
            maxLength={255}
            placeholder="Enter dealership name"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="website">
            Dealership Website
          </label>
          <input
            id="website"
            className="w-100"
            type="text"
            placeholder="Enter dealership website"
            value={website}
            onChange={handleWebsiteChange}
          />
        </div>

        <fieldset className="form-group mx-auto mb-1">
          <legend className="form-label text-cool-gray-30 size-12 fw-bold">Product(s)</legend>

          {PRODUCT_LIST.map(product => (
            <label key={product} className="product-item pe-2 d-block d-sm-inline-block">
              <input type="checkbox" name={FIELD_NAMES.PRODUCT} value={product} /> {product}
            </label>
          ))}
        </fieldset>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="goals">
            Tell us more about your goals (optional)
          </label>
          <textarea id="goals" className="w-100" value={goals} onChange={handleGoalsChange} />
        </div>

        <div className="form-submit mx-auto mb-1">
          <input type="submit" className="w-100" value="Submit" />
        </div>

        <p className="form-disclaimer mx-auto mt-0_25 text-center text-cool-gray-30 size-12">
          Your information is safe with us. We&apos;ll only use it to explore your partnership with Edmunds
        </p>
      </form>
    </div>
  );
}
